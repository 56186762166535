import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import {
  useCompaniesById,
  useGetAccountById,
  useGetCompanies,
} from "@services";
import { useSession } from "@store";
import { Company } from "@types";
import { useDebounce } from "@uidotdev/usehooks";
import { formatPhoneNumber } from "@utils/phoneNumber";
import { Key, memo, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

export const CompanySelection = memo(() => {
  const { companyId, setCompanyId, userHasRole } = useSession();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const companies = useGetCompanies({ name: debouncedSearchTerm });
  const company = useCompaniesById();
  const account = useGetAccountById();

  const items = useMemo(() => {
    const baseItems = userHasRole("super")
      ? companies.data?.items || []
      : account.data?.companies || [];

    if (
      company.data &&
      !baseItems.some((item: Company) => item._id === company.data._id)
    ) {
      return [...baseItems, company.data];
    }

    return baseItems;
  }, [
    userHasRole,
    companies.data?.items,
    account.data?.companies,
    company.data,
  ]);

  const companyChangeHandler = useCallback(
    (selection: Key | null) => {
      setCompanyId(selection as string);
      toast.success("Company changed successfully!");
    },
    [setCompanyId],
  );

  const inputChangeHandler = useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  return (
    <Autocomplete
      color="default"
      items={items}
      selectedKey={companyId}
      label="Company"
      onInputChange={inputChangeHandler}
      onSelectionChange={companyChangeHandler}
      size="lg"
      variant="bordered"
    >
      {(item: Company) => (
        <AutocompleteItem key={item._id} textValue={item.name || ""}>
          <div className="flex flex-col">
            <span className="text-small">{item.name || ""}</span>
            <span className="text-tiny">
              {formatPhoneNumber(item.phone || "")}
            </span>
          </div>
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
});
