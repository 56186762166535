import { forwardRef } from "react";
import type { ComponentProps, ReactNode } from "react";
import { Input } from "@components";
import type { InputProps } from "../ui/input";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";

export type FormTextInputProps = {
  label?: string;
  helperText?: ReactNode;
};

export const FormTextInput = forwardRef<
  HTMLInputElement,
  ComponentProps<"input"> & InputProps & FormTextInputProps
>(({ label, helperText, className, ...inputProps }, ref) => {
  return (
    <FormItem className={className}>
      <FormLabel>{label}</FormLabel>
      <FormControl>
        <Input ref={ref} {...inputProps} />
      </FormControl>
      <FormDescription>{helperText}</FormDescription>
      <FormMessage />
    </FormItem>
  );
});
