import { redirect } from "react-router-dom";
import axios from "axios";
import { store } from "@store";
import { sessionSlice } from "@store";

// Centralized auth check function that can be used by both loader and interceptor
export const checkAuth = () => {
  const accessToken = localStorage.getItem("accessToken");
  const expiration = Number(localStorage.getItem("expiration") || "");
  const currentTime = Math.floor(Date.now() / 1000);

  if (!accessToken || !expiration || expiration <= currentTime) {
    store.dispatch(sessionSlice.actions.logout());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiration");
    return false;
  }
  return true;
};

// Axios interceptor for handling unauthorized responses
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      checkAuth(); // This will handle the logout if needed
      window.location.href = "/login"; // Use window.location for async redirect
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export const authLoader = async () => {
  if (!checkAuth()) {
    return redirect("/login");
  }
  return null;
};
