import { forwardRef } from "react";
import { Textarea } from "@components";
import type { TextareaProps } from "../ui/textarea";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "../ui/form";

export type FormTextAreaProps = {
  label?: string;
  helperText?: string;
} & TextareaProps;

export const FormTextArea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps & FormTextAreaProps
>(({ label, helperText, ...textareaProps }, ref) => {
  return (
    <FormItem>
      <FormLabel>{label}</FormLabel>
      <FormControl>
        <Textarea ref={ref} {...textareaProps} />
      </FormControl>
      <FormDescription>{helperText}</FormDescription>
      <FormMessage />
    </FormItem>
  );
});
