import { ElementRef, forwardRef } from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { FormItem, FormLabel, FormControl, FormMessage, FormDescription } from "../ui/form";
import { Checkbox } from "../ui/checkbox";

type FormCheckboxInputProps = {
  helperText?: string
  label?: string
}

export const FormCheckboxInput = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & FormCheckboxInputProps>(({ 
  checked,
  helperText,
  label,
  onCheckedChange
}, ref) => {
  return (
    <FormItem className="relative flex flex-row items-start space-x-3 space-y-0 rounded-md p-4">
      <FormControl>
        <Checkbox
          ref={ref}
          checked={checked}
          onCheckedChange={onCheckedChange}
        />
      </FormControl>
      <div className="space-y-1 leading-none">
        <FormLabel>{label}</FormLabel>
        <FormDescription>{helperText}</FormDescription>
        <FormMessage />
      </div>
    </FormItem>
  );
});