import { MouseEvent, useCallback, useRef } from 'react'

export type RippleProps = {
  color?: string
}

export const Ripple = ({ color = "bg-default-active" }: RippleProps) => {

  const container = useRef<HTMLSpanElement>(null)

  const ripple = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const btn = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(btn.clientWidth, btn.clientHeight);
    const radius = diameter / 2;

    const rect = container?.current?.getBoundingClientRect()

    const rippleX = Math.round(event.clientX - ((rect?.left || 0) + radius));
    const rippleY = Math.round(event.clientY - ((rect?.top || 0) + radius));

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${rippleX}px`;
    circle.style.top = `${rippleY}px`;
    circle.classList.add("animate-ripple", "absolute", color);

    setTimeout(() => circle.remove(), 600)
    btn.appendChild(circle);
  }, [])


  return (
    <span ref={container} className='overflow-hidden absolute z-0 top-0 right-0 bottom-0 left-0 rounded-inherit' onClick={ripple} />
  )
}