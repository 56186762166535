import { forwardRef } from "react";
import type { ReactNode } from "react";
import type { SelectProps } from "@radix-ui/react-select";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "../ui/form";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "../ui/select";

export type FormSelectInputProps = {
  className?: string;
  helperText?: ReactNode;
  label?: string;
  placeholder?: string;
  rightElement?: ReactNode;
};

export const FormSelectInput = forwardRef<
  HTMLSelectElement,
  SelectProps & FormSelectInputProps
>(
  (
    {
      children,
      className,
      helperText,
      label,
      placeholder,
      rightElement,
      ...selectProps
    },
    ref,
  ) => {
    return (
      <FormItem className={className}>
        <FormLabel>{label}</FormLabel>
        <div className="flex flex-row items-center gap-2">
          <Select {...selectProps}>
            <FormControl>
              <SelectTrigger>
                <SelectValue ref={ref} placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>{children}</SelectContent>
          </Select>
          {rightElement}
        </div>
        <FormDescription>{helperText}</FormDescription>
        <FormMessage />
      </FormItem>
    );
  },
);
