import Message from "./Message";
import { memo } from "react";

type Message = {
  role:
    | "assistant"
    | "bot"
    | "function"
    | "function_call"
    | "function_result"
    | "system"
    | "tool_calls"
    | "tool_call_result"
    | "tool_call_invocation"
    | "user"
    | "tool"
    | "agent";
  secondsFromStart: number;
  message: string;
  name: string;
};

type TranscriptProps = {
  messages: Message[];
  transcript: string;
  callType: string;
};

export const Transcript = memo(
  ({ messages, transcript, callType }: TranscriptProps) => {
    const filteredMessages = messages?.filter(
      (message) =>
        message.role === "user" ||
        message.role === "assistant" ||
        message.role === "bot",
    );
    if (!transcript && !filteredMessages?.length)
      return <div className="text-center">No transcript</div>;

    const showMessages = () => {
      if (!transcript) {
        return filteredMessages?.map((msg, index) => {
          const speaker = msg.role === "user" ? "Caller" : "AI";
          const text = `${speaker}: ${msg.message}`;
          return (
            <Message
              key={index}
              speaker={speaker}
              text={text}
              callType={callType}
            />
          );
        });
      } else {
        const content = transcript.split("\n").filter(Boolean);
        return content.map((message, index) => {
          const [speaker, ...messageParts] = message.split(":");
          const speakerType = speaker === "User" ? "Caller" : "AI";
          const text = `${speakerType}: ${messageParts.join(":").trim()}`;
          return (
            <Message
              key={index}
              speaker={speakerType}
              text={text}
              callType={callType}
            />
          );
        });
      }
    };
    return <div className="flex flex-col gap-2">{showMessages()}</div>;
  },
);
