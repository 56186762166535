import { Tabs, Tab } from "@nextui-org/react";
import { useTheme } from "next-themes";
import { Outlet, useLocation } from "react-router-dom";
import { FC, memo, ReactNode, useMemo } from "react";

export type TabNavProps = {
  items: { content?: ReactNode; href: string; title: string }[];
  label: string;
};

const findMatchingRoute = (items: { href: string }[], path: string) => {
  const normalizedPath = path.length > 1 ? path.replace(/\/+$/, "") : path;

  const exactMatch = items.find(({ href }) => href === normalizedPath);
  if (exactMatch) {
    return exactMatch;
  }

  const prefixMatches = items.filter(({ href }) =>
    normalizedPath.startsWith(href + "/"),
  );

  if (prefixMatches.length === 0) {
    return undefined;
  }
  return prefixMatches.reduce((best, current) =>
    current.href.length > best.href.length ? current : best,
  );
};

export const TabNav: FC<TabNavProps> = memo(({ items, label }) => {
  const location = useLocation();
  const { theme } = useTheme();

  const matchingRoute = useMemo(
    () => findMatchingRoute(items, location.pathname),
    [items, location.pathname],
  );

  const selectedKey = useMemo(() => matchingRoute?.href, [matchingRoute]);

  return (
    <Tabs
      aria-label={label}
      classNames={{
        cursor: theme === "light" ? "!bg-white" : "!bg-stone-950",
        tab: "data-[selected=true]:font-bold data-[disabled=true]:text-stone-700",
        tabList: theme === "light" && "bg-stone-100",
        panel: "mt-6",
      }}
      items={items}
      selectedKey={selectedKey}
    >
      {({ href, title }) => (
        <Tab
          aria-label={title}
          className="flex grow"
          href={href}
          key={href}
          title={title}
        >
          <Outlet />
        </Tab>
      )}
    </Tabs>
  );
});
